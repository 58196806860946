import React, { useState, useCallback, useEffect } from "react";
import { Box, Checkbox, Grid, IconButton, TextField } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import {
  SBox1,
  SBox2,
  SBox3,
  SBox4,
  SBox5,
  SButton1,
  SButton2,
  SButton3,
  STypography1,
  STypography2,
  STypography3,
  STypography4,
  STypography5,
  STypography6,
  STypography7,
} from "../assets/style/Styles";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import cover1 from "../assets/images/banners.jpg";
import cover2 from "../assets/images/hindurojgaar-banner.jpeg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function RegistrationForm() {
  const [params, setParams] = useSearchParams();
  const datasource =
    params.get("source") !== null && params.get("source") !== ""
      ? params.get("source") === "mysba"
        ? "SBA_portal"
        : params.get("source") === "saksham"
        ? "saksham_portal"
        : "JP_FORM"
      : params.get("id") !== null && params.get("id") !== ""
      ? params.get("id") === "hindurojgaar"
        ? "hindurojgaar"
        : "JP_FORM"
      : // : params.get("Id") !== null && params.get("Id") !== ""
        // ? params.get("Id")
        // : // : "JP_FORM"
        "JP_FORM";
  const [newshow, setNewshow] = useState(false);
  const [newshow1, setNewshow1] = useState(false);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [orgid, setOrgId] = useState("");
  const [orgNo, setOrgNo] = useState("");
  const [orgName, setOrgName] = useState("");
  const [orgAddress, setOrgAddress] = useState("");
  const [newState, setNewState] = useState("");
  const [newDistrict, setNewDistrict] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [cinNo, setCinNo] = useState("");
  const [newName, setNewName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newMobile, setNewMobile] = useState("");
  const [jobrole, setJobrole] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [obj, setObj] = useState({});
  const [formDetails, setFormDetails] = useState({
    org_name: "",
    address: "",
    state: "",
    district: "",
    pincode: "",
    sector: "",
    fname: "",
    lname: "",
    email: "",
    mob_num: "",
  });
  const [submitform, setSubmitform] = useState(false);
  const [submitformOne, setSubmitformOne] = useState(false);
  const [nextStep, setNextStep] = useState(false);
  const [regId, setRegId] = useState("");
  const [show, setShow] = useState(0);
  const [universityList, setUniversityList] = useState([]);
  const [educationList, setEducationList] = useState([]);
  const [specList, setSpecList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [desiList, setDesiList] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [mobileNo, setMobileNo] = useState("");
  const [currentplan, setCurrentplan] = useState("");
  const [certificateLink, setCertficateLink] = useState("");
  const [error, setError] = React.useState(null);
  const [finalmsg, setFinalmsg] = useState({
    mindset_phrase: "Growth Oriented Entrepreneur",
    mindset_sentense:
      "You know how to grow something that is in its early stages. Focus on goal driven growth.",
    mindset_rolemodel: "Anand Mahindra",
  });
  const endOfQ12022 = dayjs("2007-03-31T23:59:59.999");
  ///modal///////////
  const [shouldScrollInViewport, setShouldScrollInViewport] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [permission, setPermission] = useState(false);
  // const closeModal = useCallback(() => setIsOpen(false), [setIsOpen]);
  // const setShouldScrollInViewportAndOpen = () => {
  //   setShouldScrollInViewport(false);
  //   setIsOpen(true);
  // };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [jobTitle, setJobTitle] = useState([
    {
      job_role: "",
      job_title: "",
      count: "",
      description: "",
    },
  ]);

  const addFields = (e) => {
    e.preventDefault();
    let newfield = {
      job_role: "",
      job_title: "",
      count: "",
      description: "",
    };
    setJobTitle([...jobTitle, newfield]);
  };

  const handleForm = (index, name, value) => {
    let data = [...jobTitle];
    data[index][name] = value;
    setJobTitle(data);
  };

  const removeField = (index) => {
    let data = [...jobTitle];
    data.splice(index, 1);
    setJobTitle(data);
  };

  const fetchStateList = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`
      );
      const data = await res.data;
      console.log("ccc");
      setState(data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDistricts = async () => {
    const params = {
      state: selectedState,
    };
    const paramString = new URLSearchParams(params).toString();
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
      );
      const data = await res.data;
      setDistrict(data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchjobrole = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/designation`)
      .then((res) => {
        setJobrole(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchIndustryFunc = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/industry`)
      .then((res) => {
        console.log("Questions get api", res.data);
        setIndustryOptions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchStateList();
    fetchjobrole();
    fetchIndustryFunc();
  }, []);

  useEffect(() => {
    fetchDistricts();
  }, [selectedState]);

  const formOne = useFormik({
    enableReinitialize: true,
    initialValues: {
      org_name: "",
      address: "",
      state: "",
      district: "",
      //pincode: "",
      //cin: "",
      name: "",
      email: "",
      mob_num: "",
    },
    validationSchema: Yup.object().shape({
      org_name: Yup.string().required("Please enter organisation name"),
      address: Yup.string().notRequired(),
      state: Yup.string().required("Please enter state"),
      district: Yup.string().required("Please enter district"),
      /*
      pincode: Yup.string()
        .notRequired()
        .length(6, "Pincode should be 6 digit")
        .matches(/^[0-9]+$/, "Must be only digits"),
        
      cin: Yup.string()
        .notRequired()
        .length(21, "CIN should be 21 digit")
        .matches(
          /^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/,
          "Enter valid CIN"
        ),
        */
      name: Yup.string().required("Please enter name"),
      email: Yup.string()
        .email("Please enter valid email")
        .required("Please enter email"),
      mob_num: Yup.string()
        .required("Please enter mobile number")
        .length(10, "Mobile number should be 10 digit")
        .matches(/^[0-9]+$/, "Must be only digits"),
    }),

    onSubmit: async (values) => {
      setOrgName(values.org_name);
      setOrgAddress(values.address);
      setNewState(values.state);
      setNewDistrict(values.district);
      //setPinCode(values.pincode);
      //setCinNo(values.cin);
      setNewName(values.name);
      setNewEmail(values.email);
      setNewMobile(values.mob_num);
      //setOrgId(data['Job Provider ID'] ? data['Job Provider ID'] : "")
      //setOrgNo(data['Job Provider Mob'] ? data['Job Provider Mob'] : "")
      //setNewshow(true);
      const waitingPopup = Swal.fire({
        title: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/job_provider_counsellor`,
          {
            org_name: values.org_name,
            address: values.address,
            state: values.state,
            district: values.district,
            //pincode: pinCode,
            //cin: cinNo,
            name: values.name,
            mobnum: values.mob_num,
            email: values.email,
            datasource: datasource,
            //jp_requirements: jobTitle,
          }
        );
        setTimeout(() => {
          waitingPopup.close(); // to close swal
        }, 0);
        Swal.fire({
          icon: "success",
          title: res.data?.status
            ? "Registered successfully"
            : "Registered successfully",
          //text: "Something went wrong.",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          setNewshow(true);
        });
      } catch (err) {
        setTimeout(() => {
          waitingPopup.close(); // to close swal
        }, 0);
        Swal.fire({
          icon: "error",
          title: err?.response?.data?.error
            ? err?.response?.data?.error
            : "Something went wrong.",
          //text: "Something went wrong.",
          showConfirmButton: false,
          timer: 3000,
        });
        setNewshow(false);
      }
    },
  });

  const formTwo = async () => {
    const waitingPopup = Swal.fire({
      title: "Please wait...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      /*
      let formdata = new FormData()
      formdata.append("org_name", orgName)
      formdata.append("address", orgAddress)
      formdata.append("state", newState)
      formdata.append("district", newDistrict)
      formdata.append("pincode", pinCode)
      formdata.append("cin", cinNo)
      formdata.append("name", newName)
      formdata.append("mob_num", newMobile)
      formdata.append("email", newEmail)
      formdata.append("jp_requirements", jobTitle)
      */

      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/candidatesearch/job_provider_counsellor`,
        {
          org_name: orgName,
          address: orgAddress,
          state: newState,
          district: newDistrict,
          //pincode: pinCode,
          //cin: cinNo,
          name: newName,
          mobnum: newMobile,
          email: newEmail,
          jp_requirements: jobTitle.map((v) => {
            return {
              job_title: v.job_title,
              job_role: v.job_role,
              count: v.count === "" ? 0 : v.count,
              description: v.description,
            };
          }),
          datasource: datasource,
        }
      );
      setTimeout(() => {
        waitingPopup.close(); // to close swal
      }, 0);
      Swal.fire({
        icon: "success",
        title: res.data?.status
          ? "Submitted successfully"
          : "Submitted successfully",
        //text: "Something went wrong.",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        formOne.resetForm();
        setOrgName("");
        setOrgAddress("");
        setNewState("");
        setNewDistrict("");
        setPinCode("");
        setCinNo("");
        setNewName("");
        setNewEmail("");
        setNewMobile("");
        setJobTitle([
          {
            job_role: "",
            job_title: "",
            count: "",
            description: "",
          },
        ]);
        setNewshow(false);
        setShow(0);
      });
    } catch (err) {
      setTimeout(() => {
        waitingPopup.close(); // to close swal
      }, 0);
      Swal.fire({
        icon: "error",
        text: "Something went wrong.",
        //text: "Something went wrong.",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        formOne.resetForm();
        setOrgName("");
        setOrgAddress("");
        setNewState("");
        setNewDistrict("");
        setPinCode("");
        setCinNo("");
        setNewName("");
        setNewEmail("");
        setNewMobile("");
        setJobTitle([
          {
            job_role: "",
            job_title: "",
            count: "",
            description: "",
          },
        ]);
        setNewshow(false);
        setShow(0);
      });
    }
  };

  const formThree = useFormik({
    enableReinitialize: true,
    initialValues: {
      org_name: "",
      address: "",
      state: "",
      district: "",
      pincode: "",
      //cin: "",
      sector: "",
      //image: "",
      name: "",
      email: "",
      mob_num: "",
    },
    validationSchema: Yup.object().shape({
      org_name: Yup.string().required("Please enter organisation name"),
      address: Yup.string().notRequired(),
      state: Yup.string().required("Please select state"),
      district: Yup.string().required("Please select district"),
      //sector: Yup.string().required("Please select sector"),
      //pincode: Yup.string()
      //.notRequired()
      //.length(6, "Pincode should be 6 digit")
      //.matches(/^[0-9]+$/, "Must be only digits"),
      /*
    cin: Yup.string()
      .notRequired()
      .length(21, "CIN should be 21 digit")
      .matches(
        /^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/,
        "Enter valid CIN"
      ),
      */

      name: Yup.string().required("Please enter name"),
      //lname: Yup.string().required("Please enter name"),
      email: Yup.string()
        .email("Please enter valid email")
        .required("Please enter email"),
      mob_num: Yup.string()
        .required("Please enter mobile number")
        .length(10, "Mobile number should be 10 digit")
        .matches(/^[0-9]+$/, "Must be only digits"),
      /*
    image: Yup.mixed()
      .notRequired()
      .test(
        "FILE_FORMAT",
        "Invalid format",
        (value) =>
          !value ||
          (value &&
            [
              "image/png",
              "image/jpg",
              "image/jpeg",
            ].includes(value?.type))
      )
      .test(
        "FILE_SIZE",
        "Please upload file < 5mb",
        (value) => !value || (value && value?.size / 1024 < 5120)
      ),
      */
    }),

    onSubmit: async (values, { resetForm }) => {
      setFormDetails({
        org_name: values.org_name,
        address: values.address,
        state: values.state,
        district: values.district,
        pincode: values.pincode,
        //cin: "",
        sector: values.sector,
        //image: "",
        fname: values.name,
        lname: values.lname,
        email: values.email,
        mob_num: values.mob_num,
      });
      setNewshow1(true);
      /*
      let formdata = new FormData()
      formdata.append("org_name", values.org_name)
      formdata.append("org_address", values.address)
      formdata.append("org_state", values.state)
      formdata.append("org_district", values.district)
      formdata.append("org_pincode", values.pincode)
      formdata.append("org_sector", values.sector)
      //formdata.append("org_logo", values.image)
      formdata.append("firstnm", values.name)
      formdata.append("lastnm", values.lname)
      formdata.append("mobnum", values.mob_num)
      formdata.append("email", values.email)
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobprovider/register`,
          formdata
        );
        Swal.fire({
          icon: "success",
          text: "Registration successful",
          //text: "Something went wrong.",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          setNewshow(true);
          formThree.resetForm();
          setSelectedState("");
          setShow(0)
        });
      } catch (err) {
        Swal.fire({
          icon: "error",
          text: "Something went wrong.",
          //text: "Something went wrong.",
          showConfirmButton: false,
          timer: 3000,
        });
        setNewshow(false);
      }
      */
    },
  });

  const formFour = async () => {
    const waitingPopup = Swal.fire({
      title: "Please wait...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/courses/course_jp_registration`,
        {
          org_name: formDetails.org_name,
          address: formDetails.address,
          state: formDetails.state,
          district: formDetails.district,
          //pincode: pincode,
          //cin: sector,
          name: formDetails.fname,
          mobnum: formDetails.mob_num,
          email: formDetails.email,
          jp_requirements: jobTitle.map((v) => {
            return {
              course_name: v.job_title,
              fee: v.job_role === "" ? 0 : v.job_role,
              duration: v.count === "" ? 0 : v.count,
              description: v.description,
            };
          }),
          datasource: datasource,
        }
      );
      const data = await res.data;
      setTimeout(() => {
        waitingPopup.close(); // to close swal
      }, 0);
      Swal.fire({
        icon: "success",
        title: "Registered successfully",
        //text: "Something went wrong.",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        formThree.handleReset();
        setJobTitle([
          {
            job_role: "",
            job_title: "",
            count: "",
            description: "",
          },
        ]);
        setNewshow1(false);
        setShow(0);
        setFormDetails({
          org_name: "",
          address: "",
          state: "",
          district: "",
          pincode: "",
          sector: "",
          fname: "",
          lname: "",
          email: "",
          mob_num: "",
        });
        setSelectedState("");
      });
    } catch (err) {
      setTimeout(() => {
        waitingPopup.close(); // to close swal
      }, 0);
      Swal.fire({
        icon: "error",
        title: err?.response?.data?.error
          ? err?.response?.data?.error
          : "Something went wrong.",
        //text: "Something went wrong.",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        formThree.handleReset();
        setJobTitle([
          {
            job_role: "",
            job_title: "",
            count: "",
            description: "",
          },
        ]);
        setNewshow1(false);
        setShow(0);
        setFormDetails({
          org_name: "",
          address: "",
          state: "",
          district: "",
          pincode: "",
          sector: "",
          fname: "",
          lname: "",
          email: "",
          mob_num: "",
        });
        setSelectedState("");
      });
    }
  };

  console.log("source:", datasource, params);

  return (
    <div>
      <img
        style={{ width: "100%", height: "auto", display: "block" }}
        src={params.get("id") === "hindurojgaar" ? cover2 : cover1}
        className="logo-img"
      />
      <SBox1>
        {show === 0 && (
          <Box
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "center",
              maxWidth: "800px",
              mt: 3,
              ml: "auto",
              mr: "auto",
            }}
          >
            <Grid container spacing={2}>
              <Grid item={6} md={6} sm={6} xs={12}>
                <Box
                  sx={{
                    height: "200px",
                    width: "280px",
                    backgroundImage:
                      "linear-gradient(-225deg, #F5CBA7 0%, #FFFDD0 48%, #FFFDD0 100%)",
                    borderRadius: "20px",
                    ml: "auto",
                    mr: "auto",
                    p: 2,
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => setShow(1)}
                  boxShadow={3}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      mt: 3,
                    }}
                  >
                    <STypography1 variant="h1">Job</STypography1>

                    <STypography1 variant="h1">Provider</STypography1>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <IconButton
                      onClick={() => setShow(1)}
                      sx={{
                        background: "#E42217",
                        color: "#FFFDD0",
                        "&:hover": {
                          background: "#E42217",
                          color: "#FFFDD0",
                        },
                      }}
                    >
                      <ArrowForwardIcon fontSize="large" />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>

              <Grid item={6} md={6} sm={6} xs={12}>
                <Box
                  sx={{
                    height: "200px",
                    width: "280px",
                    backgroundImage:
                      "linear-gradient(-225deg, #F5CBA7 0%, #FFFDD0 48%, #FFFDD0 100%)",
                    borderRadius: "20px",
                    ml: "auto",
                    mr: "auto",
                    p: 2,
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => setShow(2)}
                  boxShadow={3}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      mt: 3,
                    }}
                  >
                    <STypography1 variant="h1">Skilling</STypography1>

                    <STypography1 variant="h1">Institute</STypography1>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <IconButton
                      onClick={() => setShow(2)}
                      sx={{
                        background: "#E42217",
                        color: "#FFFDD0",
                        "&:hover": {
                          background: "#E42217",
                          color: "#FFFDD0",
                        },
                      }}
                    >
                      <ArrowForwardIcon fontSize="large" />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        {show === 2 && (
          <>
            {newshow1 ? (
              <>
                <SBox2>
                  <Grid container spacing={2} sx={{ mb: 0.5, pl: 1 }}>
                    {jobTitle.map((input, index) => (
                      <>
                        <Grid container spacing={2} sx={{ mb: 0.5, mt: 0.5 }}>
                          <Grid item lg={4} md={4} sm={6} xs={12}>
                            <SBox4>
                              <STypography4 variant="body1">
                                Course Name{" "}
                                <span
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: "13px",
                                  }}
                                >
                                  (Optional)
                                </span>
                              </STypography4>

                              <TextField
                                fullWidth
                                name="job_title"
                                type="text"
                                value={input.job_title}
                                onChange={(event) => {
                                  handleForm(
                                    index,
                                    event.target.name,
                                    event.target.value
                                  );
                                }}
                              />
                            </SBox4>
                          </Grid>

                          <Grid item lg={4} md={4} sm={6} xs={12}>
                            <SBox4>
                              <STypography4 variant="body1">
                                Course Fees{" "}
                                <span
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: "13px",
                                  }}
                                >
                                  (Optional)
                                </span>
                              </STypography4>

                              <TextField
                                fullWidth
                                name="job_role"
                                type="number"
                                InputProps={{
                                  inputProps: {
                                    min: 0,
                                  },
                                }}
                                value={input.job_role}
                                onChange={(event) => {
                                  handleForm(
                                    index,
                                    event.target.name,
                                    event.target.value
                                  );
                                }}
                              />
                            </SBox4>
                          </Grid>

                          <Grid item lg={4} md={4} sm={6} xs={12}>
                            <SBox4>
                              <STypography4 variant="body1">
                                Course Duration{" "}
                                <span
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: "13px",
                                  }}
                                >
                                  (Optional)
                                </span>
                              </STypography4>

                              <TextField
                                fullWidth
                                name="count"
                                type="number"
                                InputProps={{
                                  inputProps: {
                                    min: 0,
                                  },
                                }}
                                value={input.count}
                                onChange={(event) => {
                                  handleForm(
                                    index,
                                    event.target.name,
                                    event.target.value
                                  );
                                }}
                              />
                            </SBox4>
                          </Grid>

                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <SBox4>
                              <STypography4 variant="body1">
                                Course Description{" "}
                                <span
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: "13px",
                                  }}
                                >
                                  (Optional)
                                </span>
                              </STypography4>

                              <TextField
                                fullWidth
                                id="filled-multiline-static"
                                name="description"
                                type="text"
                                multiline
                                rows={3}
                                value={input.description}
                                onChange={(event) => {
                                  handleForm(
                                    index,
                                    event.target.name,
                                    event.target.value
                                  );
                                }}
                              />
                            </SBox4>
                          </Grid>

                          {index > 0 && (
                            <Box sx={{ width: "100%", textAlign: "right" }}>
                              <SButton3
                                sx={{ width: "30px", marginBottom: "0px" }}
                                onClick={() => removeField(index)}
                              >
                                <DeleteOutlineIcon />
                              </SButton3>
                            </Box>
                          )}

                          {jobTitle.length > 1 ? (
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <hr />
                            </Grid>
                          ) : null}
                        </Grid>
                      </>
                    ))}
                  </Grid>

                  <SButton1 onClick={(e) => addFields(e)}>
                    <STypography6 variant="body1">Add Fields</STypography6>
                  </SButton1>
                  <br />

                  <Box
                    sx={{ display: "flex", justifyContent: "center", mt: 3 }}
                  >
                    <SButton2
                      onClick={() => {
                        setNewshow1(false);
                        setJobTitle([
                          {
                            job_role: "",
                            job_title: "",
                            count: "",
                            description: "",
                          },
                        ]);
                        /*
                       formThree.resetForm();
                        setFormDetails({
                          org_name: "",
                          address: "",
                          state: "",
                          district: "",
                          pincode: "",
                          sector: "",
                          fname: "",
                          lname: "",
                          email: "",
                          mob_num: "",
                        })
                        setSelectedState("") 
                        */
                        //formTwo.resetForm();
                        //formThree.resetForm();
                      }}
                    >
                      <STypography6 variant="body1">Cancel</STypography6>
                    </SButton2>

                    <SButton1
                      onClick={() => formFour()}
                      //onClick={() => formTwo.handleSubmit()}
                      //onClick={() => setNewshow(true)}
                    >
                      <STypography6 variant="body1">Submit</STypography6>
                    </SButton1>
                  </Box>
                </SBox2>
              </>
            ) : (
              <>
                <SBox2>
                  <Box sx={{ mt: 3, mb: 2 }}>
                    <STypography7 variant="body1">
                      Organisation Details
                    </STypography7>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Name of Organisation{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <TextField
                          // disabled={nextStep}
                          type="text"
                          fullWidth
                          //onBlur={formOne.handleBlur}
                          id="org_name"
                          name="org_name"
                          size="small"
                          onChange={formThree.handleChange}
                          value={formThree.values.org_name}
                        />

                        {formThree.touched.org_name &&
                        formThree.errors.org_name ? (
                          <STypography5 variant="caption">
                            {formThree.errors.org_name}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">Address </STypography4>

                        <TextField
                          type="text"
                          fullWidth
                          //onBlur={formOne.handleBlur}
                          id="address"
                          name="address"
                          size="small"
                          onChange={formThree.handleChange}
                          value={formThree.values.address}
                        />

                        {formThree.touched.address &&
                        formThree.errors.address ? (
                          <STypography5 variant="caption">
                            {formThree.errors.address}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          State{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={state}
                            onChange={(e) => {
                              formThree.setFieldValue(
                                "state",
                                e ? e.value : ""
                              );
                              setSelectedState(e ? e.value : "");
                            }}
                            value={{ label: formThree.values.state }}
                            id="state"
                            name="state"
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Sora",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>

                        {formThree.touched.state && formThree.errors.state ? (
                          <STypography5 variant="caption">
                            {formThree.errors.state}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          District{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={district}
                            onChange={(e) => {
                              formThree.setFieldValue("district", e.value);
                            }}
                            value={{ label: formThree.values.district }}
                            id="district"
                            name="district"
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Sora",
                                fontSize: "15px",
                                textAlign: "left",
                              }),
                            }}
                          />
                        </Box>

                        {formThree.touched.district &&
                        formThree.errors.district ? (
                          <STypography5 variant="caption">
                            {formThree.errors.district}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    {/*
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Pin Code{" "}
                        </STypography4>

                        <TextField
                          type="tel"
                          inputProps={{
                            pattern:
                              "/^([+]?[s0-9]+)?(d{3}|[(]?[0-9]+[)])?([-]?[s]?[0-9])+$/",
                            maxLength: "6",
                            minLength: "6",
                            error: false,
                          }}
                          fullWidth
                          id="pincode"
                          name="pincode"
                          size="small"
                          // helperText="Select valid 6 digit pincode"
                          onChange={formThree.handleChange}
                          value={formThree.values.pincode}
                        />

                        {formThree.touched.pincode && formThree.errors.pincode ? (
                          <STypography5 variant="caption">
                            {formThree.errors.pincode}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Sector Type{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <Box sx={{ mt: 1 }}>
                          <Select
                            placeholder="Select"
                            options={industryOptions}
                            onChange={(e) => {
                              formThree.setFieldValue("sector", e.value);
                            }}
                            value={{ label: formThree.values.sector }}
                            id="sector"
                            name="sector"
                            styles={{
                              placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Inter",
                                color: "rgba(0, 0, 0, 0.3)",
                              }),
                              menu: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: "Sora",
                                fontSize: "15px",
                                textAlign: "center",
                              }),
                            }}
                          />
                        </Box>

                        {formThree.touched.sector && formThree.errors.sector ? (
                          <STypography5 variant="caption">
                            {formThree.errors.sector}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>
                    */}

                    {/*
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <SBox4>
                  <STypography4 variant="body1">
                    Organization Logo{" "}
                  </STypography4>

                  <input
                    type="file"
                    className="form-control"
                    fullWidth
                    id="image"
                    name="image"
                    onChange={formThree.handleChange}
                    value={formThree.values.image}
                  />

                  {formThree.touched.image && formThree.errors.image ? (
                    <STypography5 variant="caption">
                      {formThree.errors.image}
                    </STypography5>
                  ) : null}
                </SBox4>
              </Grid>
              */}

                    {/*
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <SBox4>
                <STypography4 variant="body1">
                  CIN
                </STypography4>

                <TextField
                  // disabled={nextStep}
                  type="tel"
                  inputProps={{
                    pattern:
                      "/^([+]?[s0-9]+)?(d{3}|[(]?[0-9]+[)])?([-]?[s]?[0-9])+$/",
                    maxLength: "21",
                    minLength: "21",
                    error: false,
                  }}
                  fullWidth
                  id="cin"
                  name="cin"
                  size="cin"
                  onChange={formOne.handleChange}
                  value={formOne.values.cin}
                />

                {formOne.touched.cin && formOne.errors.cin ? (
                  <STypography5 variant="caption">
                    {formOne.errors.cin}
                  </STypography5>
                ) : null}
              </SBox4>
            </Grid>
            */}
                  </Grid>
                  <br />

                  <Box sx={{ mt: 3, mb: 2 }}>
                    <STypography7 variant="body1">Contact Details</STypography7>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Name{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <TextField
                          // disabled={nextStep}
                          type="text"
                          fullWidth
                          //onBlur={formOne.handleBlur}
                          id="name"
                          name="name"
                          size="small"
                          onChange={formThree.handleChange}
                          value={formThree.values.name}
                        />

                        {formThree.touched.name && formThree.errors.name ? (
                          <STypography5 variant="caption">
                            {formThree.errors.name}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    {/*
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Last Name <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <TextField
                          // disabled={nextStep}
                          type="text"
                          fullWidth
                          //onBlur={formOne.handleBlur}
                          id="lname"
                          name="lname"
                          size="small"
                          onChange={formThree.handleChange}
                          value={formThree.values.lname}
                        />

                        {formThree.touched.lname && formThree.errors.lname ? (
                          <STypography5 variant="caption">
                            {formThree.errors.lname}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>
                    */}

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Email{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <TextField
                          // disabled={nextStep}
                          type="email"
                          fullWidth
                          //onBlur={formOne.handleBlur}
                          id="email"
                          name="email"
                          size="small"
                          onChange={formThree.handleChange}
                          value={formThree.values.email}
                        />

                        {formThree.touched.email && formThree.errors.email ? (
                          <STypography5 variant="caption">
                            {formThree.errors.email}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SBox4>
                        <STypography4 variant="body1">
                          Mobile Number{" "}
                          <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                        </STypography4>

                        <TextField
                          // disabled={nextStep}
                          type="tel"
                          inputProps={{
                            pattern:
                              "/^([+]?[s0-9]+)?(d{3}|[(]?[0-9]+[)])?([-]?[s]?[0-9])+$/",
                            maxLength: "10",
                            minLength: "10",
                            error: false,
                          }}
                          fullWidth
                          id="mob_num"
                          name="mob_num"
                          size="small"
                          onChange={formThree.handleChange}
                          value={formThree.values.mob_num}
                        />

                        {formThree.touched.mob_num &&
                        formThree.errors.mob_num ? (
                          <STypography5 variant="caption">
                            {formThree.errors.mob_num}
                          </STypography5>
                        ) : null}
                      </SBox4>
                    </Grid>
                  </Grid>
                  <br />

                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <SButton1
                      onClick={() => formThree.handleSubmit()}
                      //onClick={() => setNewshow(true)}
                    >
                      <STypography6 variant="body1">Submit</STypography6>
                    </SButton1>

                    <SButton2
                      onClick={() => {
                        setShow(0);
                        formThree.resetForm();
                        setSelectedState("");
                      }}
                    >
                      <STypography6 variant="body1">Back</STypography6>
                    </SButton2>
                  </Box>
                </SBox2>
              </>
            )}
          </>
        )}

        {show === 1 && (
          <>
            {newshow ? (
              <SBox2>
                <Grid container spacing={2} sx={{ mb: 0.5, pl: 1 }}>
                  {jobTitle.map((input, index) => (
                    <>
                      <Grid container spacing={2} sx={{ mb: 0.5, mt: 0.5 }}>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                          <SBox4>
                            <STypography4 variant="body1">
                              Job Title{" "}
                              <span
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                }}
                              >
                                (Optional)
                              </span>
                            </STypography4>

                            <TextField
                              fullWidth
                              name="job_title"
                              type="text"
                              value={input.job_title}
                              onChange={(event) => {
                                handleForm(
                                  index,
                                  event.target.name,
                                  event.target.value
                                );
                              }}
                            />
                          </SBox4>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                          <SBox4>
                            <STypography4 variant="body1">
                              Job Role{" "}
                              <span
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                }}
                              >
                                (Optional)
                              </span>
                            </STypography4>

                            <Select
                              placeholder="Select"
                              options={jobrole}
                              onChange={(event) => {
                                handleForm(
                                  index,
                                  "job_role",
                                  event ? event.value : ""
                                );
                              }}
                              //value={{ label: formOne.values.state }}
                              styles={{
                                placeholder: (baseStyles, state) => ({
                                  ...baseStyles,
                                  fontFamily: "Inter",
                                  color: "rgba(0, 0, 0, 0.3)",
                                }),
                                menu: (baseStyles, state) => ({
                                  ...baseStyles,
                                  fontFamily: "Sora",
                                  fontSize: "15px",
                                  textAlign: "left",
                                }),
                              }}
                            />
                          </SBox4>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                          <SBox4>
                            <STypography4 variant="body1">
                              Count{" "}
                              <span
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                }}
                              >
                                (Optional)
                              </span>
                            </STypography4>

                            <TextField
                              fullWidth
                              name="count"
                              type="number"
                              InputProps={{
                                inputProps: {
                                  min: 0,
                                },
                              }}
                              value={input.count}
                              onChange={(event) => {
                                handleForm(
                                  index,
                                  event.target.name,
                                  event.target.value
                                );
                              }}
                            />
                          </SBox4>
                        </Grid>
                        {index > 0 && (
                          <Box sx={{ width: "100%", textAlign: "right" }}>
                            <SButton3
                              sx={{ width: "30px", marginBottom: "0px" }}
                              onClick={() => removeField(index)}
                            >
                              <DeleteOutlineIcon />
                            </SButton3>
                          </Box>
                        )}
                      </Grid>
                    </>
                  ))}
                </Grid>

                <SButton1 onClick={(e) => addFields(e)}>
                  <STypography6 variant="body1">Add Fields</STypography6>
                </SButton1>
                <br />

                <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                  <SButton2
                    onClick={() => {
                      setNewshow(false);
                      setJobTitle([
                        {
                          job_role: "",
                          job_title: "",
                          count: "",
                          description: "",
                        },
                      ]);
                      formOne.resetForm();
                      setNewshow(false);
                      setOrgName("");
                      setOrgAddress("");
                      setNewState("");
                      setNewDistrict("");
                      setPinCode("");
                      setCinNo("");
                      setNewName("");
                      setNewEmail("");
                      setNewMobile("");
                      setJobTitle([
                        {
                          job_role: "",
                          job_title: "",
                          count: "",
                          description: "",
                        },
                      ]);
                      //formTwo.resetForm();
                      //formThree.resetForm();
                    }}
                  >
                    <STypography6 variant="body1">Cancel</STypography6>
                  </SButton2>

                  <SButton1
                    onClick={() => formTwo()}
                    //onClick={() => formTwo.handleSubmit()}
                    //onClick={() => setNewshow(true)}
                  >
                    <STypography6 variant="body1">Submit</STypography6>
                  </SButton1>
                </Box>
              </SBox2>
            ) : (
              <SBox2>
                <Box sx={{ mt: 3, mb: 2 }}>
                  <STypography7 variant="body1">
                    Organisation Details
                  </STypography7>
                </Box>

                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <SBox4>
                      <STypography4 variant="body1">
                        Name of Organisation{" "}
                        <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                      </STypography4>

                      <TextField
                        // disabled={nextStep}
                        type="text"
                        fullWidth
                        //onBlur={formOne.handleBlur}
                        id="org_name"
                        name="org_name"
                        size="small"
                        onChange={formOne.handleChange}
                        value={formOne.values.org_name}
                      />

                      {formOne.touched.org_name && formOne.errors.org_name ? (
                        <STypography5 variant="caption">
                          {formOne.errors.org_name}
                        </STypography5>
                      ) : null}
                    </SBox4>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <SBox4>
                      <STypography4 variant="body1">Address </STypography4>

                      <TextField
                        type="text"
                        fullWidth
                        //onBlur={formOne.handleBlur}
                        id="address"
                        name="address"
                        size="small"
                        onChange={formOne.handleChange}
                        value={formOne.values.address}
                      />

                      {formOne.touched.address && formOne.errors.address ? (
                        <STypography5 variant="caption">
                          {formOne.errors.address}
                        </STypography5>
                      ) : null}
                    </SBox4>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <SBox4>
                      <STypography4 variant="body1">
                        State{" "}
                        <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                      </STypography4>

                      <Box sx={{ mt: 1 }}>
                        <Select
                          placeholder="Select"
                          options={state}
                          onChange={(e) => {
                            formOne.setFieldValue("state", e ? e.value : "");
                            setSelectedState(e ? e.value : "");
                          }}
                          value={{ label: formOne.values.state }}
                          id="state"
                          name="state"
                          styles={{
                            placeholder: (baseStyles, state) => ({
                              ...baseStyles,
                              fontFamily: "Inter",
                              color: "rgba(0, 0, 0, 0.3)",
                            }),
                            menu: (baseStyles, state) => ({
                              ...baseStyles,
                              fontFamily: "Sora",
                              fontSize: "15px",
                              textAlign: "left",
                            }),
                          }}
                        />
                      </Box>

                      {formOne.touched.state && formOne.errors.state ? (
                        <STypography5 variant="caption">
                          {formOne.errors.state}
                        </STypography5>
                      ) : null}
                    </SBox4>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <SBox4>
                      <STypography4 variant="body1">
                        District{" "}
                        <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                      </STypography4>

                      <Box sx={{ mt: 1 }}>
                        <Select
                          placeholder="Select"
                          options={district}
                          onChange={(e) => {
                            formOne.setFieldValue("district", e.value);
                          }}
                          value={{ label: formOne.values.district }}
                          id="district"
                          name="district"
                          styles={{
                            placeholder: (baseStyles, state) => ({
                              ...baseStyles,
                              fontFamily: "Inter",
                              color: "rgba(0, 0, 0, 0.3)",
                            }),
                            menu: (baseStyles, state) => ({
                              ...baseStyles,
                              fontFamily: "Sora",
                              fontSize: "15px",
                              textAlign: "left",
                            }),
                          }}
                        />
                      </Box>

                      {formOne.touched.district && formOne.errors.district ? (
                        <STypography5 variant="caption">
                          {formOne.errors.district}
                        </STypography5>
                      ) : null}
                    </SBox4>
                  </Grid>

                  {/*
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <SBox4>
                  <STypography4 variant="body1">
                    Pin Code{" "}
                  </STypography4>

                  <TextField
                    type="tel"
                    inputProps={{
                      pattern:
                        "/^([+]?[s0-9]+)?(d{3}|[(]?[0-9]+[)])?([-]?[s]?[0-9])+$/",
                      maxLength: "6",
                      minLength: "6",
                      error: false,
                    }}
                    fullWidth
                    id="pincode"
                    name="pincode"
                    size="small"
                    // helperText="Select valid 6 digit pincode"
                    onChange={formOne.handleChange}
                    value={formOne.values.pincode}
                  />

                  {formOne.touched.pincode && formOne.errors.pincode ? (
                    <STypography5 variant="caption">
                      {formOne.errors.pincode}
                    </STypography5>
                  ) : null}
                </SBox4>
              </Grid>
              */}

                  {/*
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <SBox4>
                  <STypography4 variant="body1">
                    CIN
                  </STypography4>

                  <TextField
                    // disabled={nextStep}
                    type="tel"
                    inputProps={{
                      pattern:
                        "/^([+]?[s0-9]+)?(d{3}|[(]?[0-9]+[)])?([-]?[s]?[0-9])+$/",
                      maxLength: "21",
                      minLength: "21",
                      error: false,
                    }}
                    fullWidth
                    id="cin"
                    name="cin"
                    size="cin"
                    onChange={formOne.handleChange}
                    value={formOne.values.cin}
                  />

                  {formOne.touched.cin && formOne.errors.cin ? (
                    <STypography5 variant="caption">
                      {formOne.errors.cin}
                    </STypography5>
                  ) : null}
                </SBox4>
              </Grid>
              */}
                </Grid>
                <br />

                <Box sx={{ mt: 3, mb: 2 }}>
                  <STypography7 variant="body1">Contact Details</STypography7>
                </Box>

                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <SBox4>
                      <STypography4 variant="body1">
                        Name{" "}
                        <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                      </STypography4>

                      <TextField
                        // disabled={nextStep}
                        type="text"
                        fullWidth
                        //onBlur={formOne.handleBlur}
                        id="name"
                        name="name"
                        size="small"
                        onChange={formOne.handleChange}
                        value={formOne.values.name}
                      />

                      {formOne.touched.name && formOne.errors.name ? (
                        <STypography5 variant="caption">
                          {formOne.errors.name}
                        </STypography5>
                      ) : null}
                    </SBox4>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <SBox4>
                      <STypography4 variant="body1">
                        Email{" "}
                        <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                      </STypography4>

                      <TextField
                        // disabled={nextStep}
                        type="email"
                        fullWidth
                        //onBlur={formOne.handleBlur}
                        id="email"
                        name="email"
                        size="small"
                        onChange={formOne.handleChange}
                        value={formOne.values.email}
                      />

                      {formOne.touched.email && formOne.errors.email ? (
                        <STypography5 variant="caption">
                          {formOne.errors.email}
                        </STypography5>
                      ) : null}
                    </SBox4>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <SBox4>
                      <STypography4 variant="body1">
                        Mobile Number{" "}
                        <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                      </STypography4>

                      <TextField
                        // disabled={nextStep}
                        type="tel"
                        inputProps={{
                          pattern:
                            "/^([+]?[s0-9]+)?(d{3}|[(]?[0-9]+[)])?([-]?[s]?[0-9])+$/",
                          maxLength: "10",
                          minLength: "10",
                          error: false,
                        }}
                        fullWidth
                        id="mob_num"
                        name="mob_num"
                        size="small"
                        onChange={formOne.handleChange}
                        value={formOne.values.mob_num}
                      />

                      {formOne.touched.mob_num && formOne.errors.mob_num ? (
                        <STypography5 variant="caption">
                          {formOne.errors.mob_num}
                        </STypography5>
                      ) : null}
                    </SBox4>
                  </Grid>
                </Grid>
                <br />

                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <SButton1
                    onClick={() => formOne.handleSubmit()}
                    //onClick={() => setNewshow(true)}
                  >
                    <STypography6 variant="body1">Next</STypography6>
                  </SButton1>

                  <SButton2
                    onClick={() => {
                      setShow(0);
                      formOne.resetForm();
                      //formTwo.resetForm();
                      //formThree.resetForm();
                    }}
                  >
                    <STypography6 variant="body1">Back</STypography6>
                  </SButton2>
                </Box>
              </SBox2>
            )}
          </>
        )}
      </SBox1>
      <br />
    </div>
  );
}

export default RegistrationForm;
