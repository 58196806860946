import { Box, Button, Typography } from "@mui/material";
import styled from "@emotion/styled";

export const SBox1 = styled(Box)({
  minHeight: "100%",
});

export const STypography1 = styled(Typography)({
  color: "#000",
  fontFamily: "Sora",
  fontSize: "30px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "30px",
  paddingBottom:"16px",
  textAlign:"left"
});

export const SBox2 = styled(Box)({
  background: "#FFFFFF",
  boxShadow: "0px 2px 6px rgba(59, 80, 146, 0.25)",
  borderRadius: "10px",
  maxWidth: "1050px",
  marginTop: '20px',
  marginBottom:'20px',
  marginLeft: "auto",
  marginRight: "auto",
  padding: "15px",
});

export const SBox3 = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "8px",
});

export const STypography2 = styled(Typography)({
  fontFamily: "Sora",
  fontSize: "19px",
  fontWeight: 600,
  lineHeight: "20px",
  fontStyle: "normal",
});

export const STypography3 = styled(Typography)({
  fontFamily: "Sora",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "17.64px",
  fontStyle: "normal",
});

export const STypography4 = styled(Typography)({
  fontFamily: "Sora",
  fontSize: "15px",
  fontWeight: 600,
  lineHeight: "18.9px",
  fontStyle: "normal",
  margin: "0px",
  marginBottom: "8px",
  padding: "0px",
});

export const SBox4 = styled(Box)({
  textAlign: "left",
  margin: "8px",
  marginBottom: "0px",
  marginTop: "0px",
});

export const STypography5 = styled(Typography)({
  fontFamily: "Sora",
  fontSize: "12px",
  color: "red",
});

export const STypography6 = styled(Typography)({
  fontFamily: "Sora",
  fontSize: "15px",
  fontWeight: 600,
  lineHeight: "19px",
  fontStyle: "normal",
});

export const STypography7 = styled(Typography)({
  fontFamily: "Sora",
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "18.9px",
  fontStyle: "normal",
  margin: "0px",
  marginBottom: "8px",
  padding: "0px",
});

export const SButton1 = styled(Button)({
  background: "#FF7A00",
  border: "1px solid #FF7A00",
  boxShadow: "0px 2px 6px rgba(59, 80, 146, 0.25)",
  color: "#FFFFFF",
  borderRadius: "5px",
  textTransform: "none",
  padding: "16px",
  paddingTop: "12px",
  paddingBottom: "12px",
  margin: "8px",
  width: "150px",
  "&:hover": {
    background: "#FF7A00",
    border: "1px solid #FF7A00",
  },
});

export const SButton2 = styled(Button)({
  background: "#FFFFFF",
  border: "2px solid #FFFFFF",
  color: "#FF7A00",
  borderRadius: "5px",
  boxShadow: "0px 2px 6px rgba(59, 80, 146, 0.25)",
  textTransform: "none",
  padding: "16px",
  paddingTop: "12px",
  paddingBottom: "12px",
  margin: "8px",
  width: "150px",
  "&:hover": {
    background: "#FFFFFF",
  },
});

export const SButton3 = styled(Button)({
  background: "#FFFFFF",
  border: "2px solid #FFFFFF",
  color: "#3B5092",
  borderRadius: "5px",
  boxShadow: "0px 2px 6px rgba(59, 80, 146, 0.25)",
  textTransform: "none",
  margin: "8px",
  width: "30px",
  "&:hover": {
    background: "#FFFFFF",
  },
});

export const SBox5 = styled(Box)({
  padding: "8px",
  paddingTop: "0px",
  paddingBottom: "16px",
  textAlign: "left",
});
